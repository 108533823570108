import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, map, Observable, startWith, Subject } from 'rxjs';
import { ApiResponse } from '../../models/api.response';
import { AssignReviewer } from '../../models/audit/assign-task.model';
import { GlobalService } from '../../services/global.service';
import { IntegrationsService } from '../../services/integrations.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AuditService } from '../../services/audit.service';
import { SnackbarService } from '../../services/snackBarService.service';

@Component({
  selector: 'app-audit-delegate-tasks',
  templateUrl: './audit-delegate-tasks.component.html',
  styleUrls: ['./audit-delegate-tasks.component.scss']
})
export class AuditDelegateTasksComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  @ViewChild('groupInput') groupInput: ElementRef<HTMLInputElement>;
  private subjectKeyup = new Subject<any>();
  searchCheck: boolean = false;
  stateCtrl: FormControl;
  filteredStates: Observable<any[]>;
  cloudReviewers: AssignReviewer[];
  reviewer: AssignReviewer;
  userType: string;
  selectedTab: string = 'User';
  ssoEnabled: boolean;
  title = 'toaster-not';
  auditType: string;
  selfDelegation: boolean = false;
  isVisible: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<AuditDelegateTasksComponent>,
    private globalService: GlobalService,
    private integrationService: IntegrationsService,
    private auditService: AuditService,
    private cdref: ChangeDetectorRef) {

    this.reviewer = new AssignReviewer();
    this.cloudReviewers = [];
    this.reviewer.reviewerDetails = [];
    this.reviewer.auditDetails = [];
    this.reviewer.unSelectedIds = [];
  }

  ngOnInit(): void {
    this.subjectKeyup.pipe(
      debounceTime(500)).subscribe((keyword) => {
        if (this.selectedTab == 'User') {
          this.searchDelegateUser(keyword);
        } else {
          this.searchDelegateGroup(keyword);
        }
      });

    this.stateCtrl = new FormControl();
    this.userType = this.globalService.user.userType;
    this.ssoEnabled = this.globalService.user.ssoStatus == 1 ? true : false;
    this.auditType = this.data.auditType;
    this.selfDelegation = this.data.selfDelegation;
  }

  searchDelegateUser(keyword) {
    this.searchCheck = false;
    let delegate = {
      keyword: this.userInput.nativeElement.value,
      userType: this.globalService.user.userType,
      accessId: this.globalService.user.accessId
    }
    this.cloudReviewers = [];
    this.integrationService.getUsers(delegate).subscribe((res: any) => {
      this.cloudReviewers = res.data;
      const index = this.cloudReviewers.findIndex(r => r.email == this.globalService.user.email)
      if (index > -1) {
        this.cloudReviewers.splice(index, 1);
      }

      this.searchCheck = true;
      if (res.data.length == 0 && !this.ssoEnabled) {
        this.cloudReviewers = [];
        let regExp = new RegExp(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        if (regExp.test(keyword)) {
          let cloudReviewer = new AssignReviewer();
          cloudReviewer.email = keyword;
          cloudReviewer.uuid = 'fdsaerafdafds' + keyword.length;
          this.cloudReviewers.push(cloudReviewer);
        }
      }
    });
  }

  searchDelegateGroup(keyword) {
    let delegate = {
      keyword: keyword,
      accessId: this.globalService.user.accessId
    }

    this.cloudReviewers = [];
    this.integrationService.getGroups(delegate).subscribe((res: ApiResponse<AssignReviewer[]>) => {

      this.cloudReviewers = res.data;
      this.searchCheck = true;
    });
  }

  search(keyword) {
    this.subjectKeyup.next(keyword);
  }

  close() {
    this.dialogRef.close();
  }

  cancel() {
    this.isVisible = false;
    this.userInput.nativeElement.value = '';
    this.reviewer.reviewerDetails = [];
    this.reviewer.name = '';
  }


  remove(reviewer: any): void {
    const index = this.reviewer.reviewerDetails.indexOf(reviewer);

    if (index >= 0) {
      this.reviewer.reviewerDetails.splice(index, 1);
      this.reviewer.reviewerDetails.splice(index, 1);
    }
  }
  
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if(this.restrictDelegationsCheck() && this.reviewer.reviewerDetails.length > 0){
      this.userInput.nativeElement.value = '';
      this.snackbarService.open('Restrict to select only one reviewer', 'Close', 'warn');
      return;
    }
    this.userInput.nativeElement.value = '';
    if (event.option.value.type == 'Group') {
      this.reviewer.keyword = event.option.value.name;
      this.reviewer.groupMembers = event.option.value.groupMembers;
      this.isVisible = true;
      this.reviewer.reviewerDetails = [event.option.value];
    } else {
      // dont add if already added
      if (this.reviewer.reviewerDetails.find(x => x.uuid == event.option.value.uuid)) {
        this.snackbarService.open('Reviewer already added', 'Close', 'error');
        return;
      }

      // if (this.data.auditDetails.some(ad => ad.userInfo.email == event.option.value.email && !this.selfDelegation)) {
      //   this.snackbarService.open(`
      //   The following users [${event.option.value.email}] will not be assigned to the reviewer(s) because the reviewer(s) cannot review their own access.`, 'X', 'error');
      //   return;
      // }

      this.reviewer.reviewerDetails.push(event.option.value);
    }

    this.cloudReviewers = [];
  }

  assignUser() {
    this.reviewer.companyId = this.globalService.company.id;
    this.reviewer.accessId = this.globalService.user.accessId;
    this.data.auditDetails?.forEach(audit => {
      if(this.data?.extendedView){
        this.reviewer.auditDetails?.push({ id: audit.userInfo.id , task_ids: [audit.id], auditId: this.data.auditId });
      }else{
        this.reviewer.auditDetails?.push({ id: audit.id, auditId: this.data.auditId });
      }
    });
    if(this.data?.selectAllUsers){
      this.data?.unselectedIds?.forEach(audit => {
        this.reviewer.unSelectedIds?.push(audit.id);
      });
    }
    // this.reviewer.auditDetails.push(this.data.auditDetails)
    this.reviewer.selectAll = this.data?.selectAllUsers;
    this.reviewer.userId = this.globalService.user.id;
    this.reviewer.auditType = this.data.auditType;
    this.reviewer.auditId = this.data.auditId;
    this.reviewer.auditType = this.data.auditType;
    this.auditService.delegateUser(this.reviewer).subscribe((res: ApiResponse<AssignReviewer>) => {
      this.snackbarService.open(res.message, 'Close', 'success');
      this.dialogRef.close();
    });
  }

  restrictDelegationsCheck(){
    if(this.globalService.company.restrictMultipleDelegation && this.auditType == 'resource'){
      return true;
    }else{
      return false;
    }
  }

  assignGroup() {
    this.reviewer.companyId = this.globalService.company.id;
    this.reviewer.accessId = this.globalService.user.accessId;
    this.data.auditDetails?.forEach(audit => {
      if(this.data?.extendedView){
        this.reviewer.auditDetails?.push({ id: audit.userInfo.id , task_ids: [audit.id], auditId: this.data.auditId });
      }else{
        this.reviewer.auditDetails?.push({ id: audit.id, auditId: this.data.auditId });
      }
    });
    if(this.data?.selectAllUsers){
      this.data?.unselectedIds?.forEach(audit => {
        this.reviewer.unSelectedIds?.push(audit.id);
      });
    }
    // this.reviewer.auditDetails = this.data.auditDetails;
    this.reviewer.selectAll = this.data?.selectAllUsers;
    this.reviewer.userId = this.globalService.user.id;
    this.reviewer.auditType = this.data.auditType;
    this.reviewer.auditId = this.data.auditId;
    this.reviewer.auditType = this.data.auditType;
    this.auditService.delegateGroup(this.reviewer).subscribe((res: ApiResponse<AssignReviewer>) => {
      this.snackbarService.open(res.message, 'Close', 'success');
      this.dialogRef.close();
    });
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedTab = tabChangeEvent.index == 0 ? 'User' : 'Group';
    this.cloudReviewers = [];
    this.reviewer.reviewerDetails = [];
    this.reviewer.reviewAllStatus = 0;
    this.cancel();
  }

  searchGroups(event) {
    this.isVisible = false;
    if (event.target.value.length >= 3) {

      let delegate = {
        keyword: event.target.value,
        accessId: this.globalService.user.accessId
      }

      this.cloudReviewers = [];
      this.integrationService.getGroups(delegate).subscribe((res: ApiResponse<AssignReviewer[]>) => {
        this.cloudReviewers = res.data;
        this.setData();
      });
    }
  }

  setData() {
    this.stateCtrl = new FormControl();
    this.filteredStates = this.stateCtrl.valueChanges.pipe(
      startWith(''),
      map(state => state ? this.filterStates(state) : this.cloudReviewers.slice())
    );
  }

  filterStates(name: string) {
    return this.cloudReviewers.filter(state =>
      state.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }



}
