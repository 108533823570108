
export class AssignReviewer {
    //updated
    groupId: string;
    reviewersGroupId: string;
    groupName: string;
    reviewersGroupName: string;
    //  
    accessId: number;
    uuid: string;
    name: string;
    type: string;
    status: string;
    email: string;
    managerEmail: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    keyword: string;
    assignType: number;
    groupMembers: number = 9;
    // delegate

    companyId: number;
    auditId: number;
    userId: number;
    auditDetails: AuditDetails[];
    unSelectedIds: AuditDetails[];
    auditType: string;
    reviewerDetails: ReviewerDetails[];
    reviewAllStatus: number;
    selectAll: boolean = false;
    allowOverride: boolean = true;
}

export interface AuditDetails {
    id: number;
    auditId: number;
    task_ids?: any;
}

export interface ReviewerDetails {
    uuid: string;
    name: string;
    type: string;
    email: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    groupMembers: number;



}


